import { pick } from 'lodash-es';
import type { CarRentFeature, CarSpecs, CarColor, SubscriptionBooking, CartListItem, CarRentFeatureItem, RentBooking } from '~/types';
import { carRentFeatures } from '~/mocks';
import { sanitizeSumItems, sumCartListItems } from './helpers';
interface SelectedCar extends Partial<CarSpecs> {
  color?: CarColor
}

interface State {
  featuresLoaded: boolean
  selectedCar: SelectedCar
  selectedBooking: SubscriptionBooking
  selectedRent: RentBooking
  features: CarRentFeature[]
  featuresWithVModel: CarRentFeature[]
}

const getSelectedRentInitialMonth = (): string => {
  const now = new Date();
  now.setMonth(now.getMonth() + 1);

  return now.toISOString(); ;
};

export const useCartStore = defineStore('cartStore', {
  persist: {
    storage: persistedState.cookiesWithOptions({ sameSite: 'strict', maxAge: 365 * 24 }),
    key: 'cartStore',
    paths: ['selectedCar', 'selectedBooking', 'featuresWithVModel'],
  },
  state: (): State => ({
    featuresLoaded: false,
    features: [],
    featuresWithVModel: [],
    selectedCar: {},
    selectedBooking: {
      start: new Date().toISOString(),
      monthsLong: '24',
      station: undefined,
      km: '',
    },
    selectedRent: {
      start: new Date().toISOString(),
      timeStart: undefined,
      end: getSelectedRentInitialMonth(),
      timeEnd: undefined,
      station: undefined,
    },
  }),
  actions: {
    // features are different per model or category
    async loadFeatures () {
      this.featuresLoaded = false;
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(this.handleFeatures(carRentFeatures));
        }, 1000);
      });
    },
    handleFeatures (features: CarRentFeature[]) {
      this.setFeatures(features);
      this.bindVModelsToStore();
      this.featuresLoaded = true;
    },
    setFeatures (features: CarRentFeature[]) {
      this.features = [...features];
    },
    bindVModelsToStore () {
      const setDefaultAmountAndQty = (featureType: CarRentFeature['type'], option: CarRentFeatureItem): Partial<CarRentFeatureItem> => {
        const partial = {
          ...option,
          amount: 0,
          qty: 0,
        };

        return partial;
      };

      try {
        const tempFeaturesWithVModel: CarRentFeature[] = [];
        this.features.forEach((feature: CarRentFeature) => {
          const partialFeature = {
            ...feature,
            options: feature.options.map(option => setDefaultAmountAndQty(feature.type, option)),
          };

          tempFeaturesWithVModel.push(partialFeature as CarRentFeature);
        });
        this.featuresWithVModel = [...tempFeaturesWithVModel];
      } catch (error) {
        throw new Error(`Error while binding v-models to store: ${JSON.stringify(error)}`);
      }
    },
    setSelectedCar (car: CarSpecs) {
      type PickedKeys = keyof CarSpecs;
      const keys: PickedKeys[] = ['id', 'make', 'model', 'price', 'imageSrc', 'color', 'meta'];
      this.selectedCar = pick(car, keys);
    },
  },
  getters: {
    oncePaymentTypeFeatures (state): CarRentFeature[] {
      return state.featuresWithVModel.filter(feature => feature.paymentType === 'once');
    },
    oncePaymentFeaturesItems (): {items: CartListItem[], totalAmount: number } {
      const items = sanitizeSumItems(this.oncePaymentTypeFeatures);

      return {
        items,
        totalAmount: sumCartListItems(items),
      };
    },
    recurrentPaymentTypeFeatures (state): CarRentFeature[] {
      return state.featuresWithVModel.filter(feature => feature.paymentType === 'recurrent');
    },
    recurrentPaymentFeaturesItems (state): {items: CartListItem[], totalAmount: number } {
      const items = sanitizeSumItems(this.recurrentPaymentTypeFeatures);
      const vehiclePriceFromString = state.selectedCar?.price?.match(/(\d+)/)?.[0] || 0;
      const carSelectionAsItem: CartListItem = {
        label: state.selectedCar?.model || '-',
        totalPrice: Number(vehiclePriceFromString),
      };
      items.unshift(carSelectionAsItem);

      return {
        items,
        totalAmount: sumCartListItems(items),
      };
    },
    computedTotal (): number {
      return this.oncePaymentFeaturesItems.totalAmount +
        this.recurrentPaymentFeaturesItems.totalAmount;
    },
  },
});
