import type { CarRentFeature } from '~/types';

export const carRentFeatures: CarRentFeature[] = [
  {
    id: 'ai98',
    name: 'Sillas para bebé',
    description: 'Texto descriptivo de las sillas para bebés.',
    type: 'incremental',
    paymentType: 'once',
    options: [
      { id: 'a001', label: 'Silla para bebé de 1 a 5 años', unitPrice: 32 },
      { id: 'a002', label: 'Silla para niños de 5 a 10 años', unitPrice: 30 },
      { id: 'a003', label: 'Elevador para niños de 7 a 12 años', unitPrice: 12 },
    ],
  },
  {
    id: 'ai938',
    name: 'Conductor adicional',
    description: 'Texto descriptivo para conductores adicionales.',
    type: 'incremental',
    paymentType: 'recurrent',
    options: [
      { id: 'g002', label: 'Conductor nobel', note: 'menor de 27 años', unitPrice: 50 },
      { id: 'g003', label: 'Conductor', note: 'entre 27 y 65 años', unitPrice: 38 },
      { id: 'g004', label: 'Conductor senior', note: 'mayor de 65', unitPrice: 25 },
    ],
  },
  {
    id: 'co28',
    name: 'Envío a domicilio',
    paymentType: 'once',
    description: 'Texto descriptivo del envío a domicilio.',
    type: 'boolean',
    options: [
      { id: 'p062', label: 'Envío a domicilio', unitPrice: 3 },
    ],
  },
  {
    id: 'co68',
    name: 'Cadenas de nieve',
    paymentType: 'once',
    description: 'Texto descriptivo de cadenas de nieve.',
    type: 'boolean',
    options: [
      { id: 'z322', label: 'Cadenas de nieve', unitPrice: 6 },
    ],
  },
  {
    id: 'co98',
    name: 'Punto de recogida',
    description: 'Texto descriptivo de punto de recogida',
    type: 'selectable',
    paymentType: 'once',
    options: [
      { id: '0', label: 'Lo recojo yo' },
      { id: 'ee022', label: 'Recogida en Aeropuerto Madrid Barajas', unitPrice: 28 },
      { id: 'g4022', label: 'Recogida en Santiago Bernabeu', unitPrice: 8 },
      { id: 'hj22', label: 'Recogida en Pozuelo de Alarcón', unitPrice: 26 },
    ],
  },
];
